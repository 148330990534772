@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Updock&display=swap');

$white: #FFF;
$lightgrey: #d3d3d3;
$black: #000;
$goldenrod: #DAA520;
$gold: #FFD700;
$warmbrown: #bf7e1a;
$goldenbrown: #a96f18;

html{
  width: 100%;
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:link{
  color: #1a0dab;
  text-decoration: none;
}

a:visited{
  color: #660099;
  text-decoration: none;
}

a:hover{
  color: #1a0dab;
  text-decoration: underline $gold;
}

// ipadmini 768
@media screen and (min-width:768px){
  .pc{
    display: block;
    text-align: center;
  }

  .sp{
    display: none;
  }
}

@media screen and (max-width:767px){
  .pc{
    display: none;
  }

  .sp{
    display: block;
    text-align: center;
  }
}

/*
  HERO
*/

.hero{
  background-image: url('./images/hero/hero-image1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  padding: 0;
  margin-bottom: 1rem;

  nav{
    padding: 1rem .25rem;

    .nav-link:link{
      color: #DF3F3E;
      font-weight: bold;
      text-shadow: 1px 1px #FFCB4E;
    }

    .nav-link:visited{
      color: #DF3F3E;
      font-weight: bold;
      text-shadow: 1px 1px #FFCB4E;
    }

    .nav-link:hover{
      color: #DF3F3E !important;
      text-shadow: 1px 1px #FFCB4E;
      text-decoration: underline #DF3F3E;
    }
  }

  .messageBoard{
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 5rem;

    p{
      text-align: center;
      padding-bottom: 6rem;
      letter-spacing: .2rem;
      color: #FFD887;
      font-weight: bold;
      text-shadow: 3px 3px rgb(230, 30, 11, .75);

      h1{
        font-size: 2.5rem;
      }
    }

  }

  a{
    margin: 0 .25rem;
  }
}

@media screen and (max-width:768px){
  .hero{
    height: 65vh;

    nav{
      padding: .25rem .2rem;
    }

    .messageBoard{
      font-size: 1.25rem;
    }
  }
}

.page-hero{
  @extend .hero;
  background-image: url('./images/hero/contents_background.jpg');
}

/*
  INDEX AWARDS
*/

.index-awards{
  justify-content: center;

  .col{
    padding: 1rem;
    text-align: center;

    img{
      border-top: .25rem solid #B37D4D;
      border-right: .25rem solid #B37D4D;
      border-bottom: .25rem solid #D2AE7E;
      border-left: .25rem solid #D2AE7E;
      box-shadow: inset 0 0 10px #000;
      display: inline-block;
      margin-bottom: .25rem;
    }

    small{
      color: grey;
    }
  }
}

/*
  SIDE NAV
*/

.side-nav-toggle{
  background: rgb(255, 255, 255, .5);
}

.sideNav{
  background-color: #FFD887;
  color: white;
}


/*
  GENDER BOARD
*/

.gender-board-men{
  background-color: #fff;
  background-image: url('./images/gender-background/men.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-position: left;
  min-width: 450px;
  min-height: 450px;
  color: $goldenbrown;
  text-align: right;
  padding: 1rem 2rem 0 0;
  line-height: 1.25rem;
  font-size: .75rem;

    ul{
      padding: 0;
      list-style: none;
    }

    .gender-text{
      font-family: 'Updock', cursive;
      font-size: 5rem;
      color: #00bfff;
    }

    .gender-section{
      font-size: 1rem;
      font-weight: bold;
    }
}

.gender-board-women{
  @extend .gender-board-men;
  background-image: url('./images/gender-background/women.jpg');
  background-repeat: no-repeat;
  background-position: top;
  background-position: right;
  text-align: left;
  padding: 1rem 0 0 2rem;

  .gender-text{
    color: #f08080;
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px){
  .gender-board-men{
    background-position: -7rem;
    padding: 1rem;
    margin-bottom: 1rem;
    min-width: 50%;
  }

  .gender-board-women{
    background-position: 1rem;
  }
}

@media screen and (max-width: 480px){
  .gender-board-men{
    background-position: -7rem;
    padding: 1rem;
    margin-bottom: 1rem;
    min-width: 100%;
  }

  .gender-board-women{
    @extend .gender-board-men;
    background-position: 1rem;
  }
}

/*
  INDEX BANNER AREA
*/

.index-banner-area{
  width: 100%;
  text-align: center;

  img{
    margin-bottom: 1rem;
  }
}

/*
  SECTION WRAP
*/

.section-wrap{
  text-align: center;

  .section-title{
    border-bottom: 2px solid $gold;
    border-image: linear-gradient(0.25turn, $white , $goldenbrown , $white);
    border-image-slice: 1;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
    font-size: 1.8rem;
    letter-spacing: .25rem;
    display: inline-block;
    padding: 0 3rem .25rem 3rem;
  }
}

/*
  INDEX ABOUT
*/

.index-about-wrap{
  display: flex;
  margin-bottom: 1rem;

  .index-about-text{
    padding: 3rem;
    font-size: .75rem;
    line-height: 1.25rem;

    h3{
      font-size: 1rem;
    }

    .whats-about-outer{
      border: 1px double $gold;
      -webkit-transform: rotate(2deg);
      -moz-transform: rotate(2deg);
      transform: rotate(2deg);
      padding: .25rem;
      margin-bottom: 3rem;

      .whats-about-inner{
        -webkit-transform: rotate(-2deg);
        -moz-transform: rotate(-2deg);
        transform: rotate(-2deg);
        border: 1px double $goldenbrown;
        padding: 1rem;
      }
    }

    .index-about-profiles{

      .profile-wrap{
        display: flex;
        margin-bottom: 1rem;

        img{
          height: 240px;
          padding-top: 1rem;
        }

        .profile-text{
          padding: 1rem;

          h6{
            font-weight: bold;
          }

          p{
            margin-bottom: .25rem;
          }

          ul{
            padding: 0;
            list-style: none;
          }

          a:link{
            color: #1a0dab;
          }

          a:visited{
            color: #660099;
          }

          a:hover{
            text-decoration: underline;
          }
        }
      }

      @media screen and (max-width: 480px){
        .profile-wrap{
          text-align: center;
          display: block;

          img{
            display: block;
            margin: 0 auto;
            margin-bottom: 1rem;
          }

          .profile-text{
            width: 100%;
          }
        }
      }

    }
  }
}

@media screen and (max-width: 480px){
  .index-about-wrap{
    display: block;

    .index-about-text{
      padding: 0;
    }
  }
}

/*
  INDEX NEWS
*/

.index-news{
  color: black;
  margin-bottom: 5rem;

  ul{
    display: table;
    margin: 0 auto;
    padding: 0;

    li{
      list-style: none;
      line-height: 2rem;
      display: inline;

      .date{
        background-color: #eee;
        padding: .25rem .25rem;
        border-radius: .25rem;
        font-size: .75rem;
      }

      .title{
        margin-bottom: .25rem;
      }

      a:link{
        color: #222;
        text-decoration: none;
      }

      a:visited{
        color: #222;
        text-decoration: none;
      }

      a:hover{
        color: #222;
        text-decoration: underline;
      }
    }
  }
}

/*
  INDEX MEMBERSHIP
*/

.index-membership{
  margin: 0 auto;
}

.membership-wrap{
  padding: 0 5rem;
  display: flex;

  .membership-list{
    padding-left: 1.5rem;
    font-size: .75rem;
    margin-bottom: .25rem;

    h4{
      margin-bottom: 0;

      .membership-step{
        font-family: 'Updock', cursive;
        font-size: 3rem;
      }

      .membership-step-number{
        @extend .membership-step;
        font-size: 4rem;
        margin-right: .25rem;
      }
    }

    .membership-step-content span{
      background:linear-gradient(transparent 60%, #ff6 60%);
    }
  }

  @media screen and (max-width: 480px){
    .membership-list{
      padding-left: 0;
    }
  }
}

@media screen and (max-width: 768px){
  .membership-wrap{
    padding: 0;
  }
}

/*
  INDEX MARRIAGE
*/

.index-marriage{

  col{
    padding: .25rem;
  }

  a:link {
    color: #444;
    text-decoration: none;
  }

  a:visited {
    color: #444;
  }

  a:hover {
    color: #444;
  }

  .card{
    padding: .25rem;
    font-size: .8rem;
    border: none;

    img{
      margin: 0 auto;
    }

    article{
      padding: 1rem;
      line-height: 1.5rem;

      svg{
        margin-right: .25rem;
        padding-bottom: .15rem;
      }
    }
  }
}

@media screen and (max-width: 768px){
  .index-marriage{
    padding: 0;
    margin: 0;
  }
}

/*
  MARRIAGE LIST
*/

.marriage-list-wrap{
  padding: 2rem 10rem;
  font-size: .75rem;
  line-height: 1.25rem;

  a:link{
    color: #222;
  }

  a:visited{
    color: #222;
  }

  a:hover{
    color: #222;
    text-decoration: none;
  }

  .marriage-list-body{
    display: flex;
    margin-bottom: 2rem;

    img{
      padding: 1rem;
      display: block;
      width: 320px;
      height: 240px;
    }
  }
}

@media screen and (max-width: 820px){
  .marriage-list-wrap{
    padding: 0;

    .marriage-list-body{
      display: block;

      img{
        margin: 0 auto;
        width: 320px;
        height: 240px;
      }
    }
  }
}

/*
  CONTENTS LIST WRAP
*/

.contents-list-wrap{
  padding: 2rem 10rem;
  font-size: .75rem;
  line-height: 1.25rem;

  .contents-list-body{
    display: flex;
    margin-bottom: 1rem;
  }

  a:link{
    color: #222;
  }

  a:visited{
    color: #222;
  }

  a:hover{
    color: #222;
    text-decoration: none;
  }
}

@media screen and (max-width: 820px){
  .contents-list-wrap{
    padding: 0;

    .contents-list-body{
      display: block;

      aside{
        img{
          margin: 0 auto;
        }
      }
    }
  }
}

/*
  LIST WRAP for NEWS
*/

.list-wrap{
  padding: 2rem 10rem;
  font-size: .75rem;
  line-height: 1.25rem;

  a:link{
    color: #222;
  }

  a:visited{
    color: #222;
  }

  a:hover{
    color: #222;
    text-decoration: none;
  }
}

.news-list-body{
  margin-bottom: 2rem;

  h6{
    font-weight: bold;
    font-size: .85rem;
  }

  p{
    margin-bottom: .2rem;
  }

  span{
    color: lightgray;
  }
}


/*
  PROFILE ICON
*/

.profile-icon{
  margin-right: .25rem;
  padding-bottom: .2rem;
}

/*
  INDEX COURSE
*/

.index-course-wrap{
  text-align: center;

  h5{
    font-style: italic;
    font-size: 2rem;
    color: $goldenbrown;
    margin: 2rem 0;
  }

  table{
    margin: 0 auto;
    margin-bottom: 1rem;

    td{
      padding: .25rem;
    }
  }

  .annual-income{
    font-size: .85rem;
    color: #888;
  }

  .service{
    ul{
      display: flex;
      list-style: none;
      padding: 1rem 10rem 5rem 10rem;
      margin: 0 auto;
      justify-content: center;

      li{
        padding: 1rem 2rem;
        border-radius: 10px;
        border: 1px solid #555;
        margin-right: 1rem;
        padding-bottom: 1.25rem;
        white-space: nowrap;

        svg{
          margin-right: .25rem;
          padding-bottom: .25rem;
        }
      }

      li:last-child{
        @extend li;
        margin-right: 0;
      }
    }
  }

  .price-description{
    font-size: .85rem;
    color: #888;

    ul {
      list-style: none;
    }
  }
}

@media screen and (max-width: 820px){
  .index-course-wrap{
    .service{
      ul{
        display: block;
        padding: 0;

        li{
          margin: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

/*
  MARRIAGE DETAIL WRAP
*/

.marriage-detail-wrap{
  padding: 2rem 4rem;
  line-height: 2rem;
  font-size: .85rem;

  .marriage-detail-header{
    width: 100%;
    display: flex;
    align-items: flex-end;
    padding: 1rem;

    section{
      padding: 1rem;
    }
  }

  p{
    padding: .5rem;
  }
}

@media screen and (max-width: 480px){
  .marriage-detail-wrap{
    padding: 0;

    .marriage-detail-header{
      display: block;
      padding: 0;

      img{
        width: 50%;
        display: block;
        margin: 0 auto;
      }
    }
  }
}

/*
  PAGINATION
*/

.paginate{
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-top: 2rem;

  li{
    display: inline;
    list-style: none;
    padding: .25rem .75rem;
    border-radius: .25rem;
    margin-right: 1rem;

    a:link{
      color: #1a0dab;
    }

    a:visited{
      color: #660099;
    }

    a:hover{
      text-decoration: underline;
    }

  }

  li:last-child{
    margin-right: 0;
  }

  .paginate_disabled{
    display: none;
  }

  .selected{
    border: 1px solid $goldenrod;
  }
}

.contact-button{
  margin: 0 auto;
  text-align: center;
  padding: 1rem 0;

  a.btn {
    border: 2px solid $goldenbrown;
    color: #000;
    padding: .25rem 2rem;
    border-radius: 100vh;

    svg{
      margin-right: .25rem;
      padding-bottom: .1rem;
    }
  }

  a.btn:hover {
    background-color: #FCFAF2;
    text-decoration: none;
  }
}

.and-more{
  text-align: end;
  padding: 1rem;
}

/*
  CHILD PAGE WRAP
*/

.child-page-wrap{
  line-height: 2rem;
  font-size: .85rem;

  .title{
    font-size: 2rem;
    padding: 2rem 1rem;
  }

  .body{
    padding: 1rem;
  }
}

/*
  CHILD TITLE
*/

.child-title{
  display: table;

  .child-tilte-text{
    font-size: .85rem;
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    background-image: url('./images/svg/childpage_bg_vector.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
    background-size: 100%;

    .child-title-text-inner{
      padding: 1rem;
      border-left: .5rem $gold solid;

      svg{
        padding-bottom: .15rem;
        margin-right: .25rem;
      }

      h2{
        font-size: 1.5rem;
      }
    }
  }

  .child-title-image{
    img{
      width: 480px;
    }
  }

  .child-title-body{
    padding: 1rem;
  }
}

@media screen and (max-width:820px){
  .child-title{
    display: table;

    .child-tilte-text{
      font-size: 1rem;
      width: 100%;
      height: 300px;
      background-size: 100%;
    }

    .child-title-image{
      display: none;

      img{
        display: none;
      }
    }
  }
}

/*
  CHILD BODY
*/

.child-body{
  padding: 2rem;
  line-height: 2.25rem;
}

/*
  FOOTER
*/

.footer{
  background: $goldenbrown;
  color: $white;
  text-align: center;
  font-size: .75rem;
  padding: .25rem 0;
  margin-top: 2rem;
}